.admin-announcement-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2%;
    background-image: url('../../public/images/robo-rally-text.png');
    background-size: contain;
    /* Resmi kapsayacak şekilde ayarlar */
    background-position: center;
    /* Resmi ortalar */
    background-repeat: no-repeat;
    /* Resmin tekrarını engeller */
    width: 100vw;
    min-height: 120vh;

}

.admin-first {
    border: 0.1rem solid black;
    border-radius: 4rem;
    background-color: #fff;
    display: inline-block;
    overflow: hidden;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.admin-first-text {
    margin: 0.5rem;
    font-size: 1vw;
    font-family: "PoppinsLight";
    padding: 0vw 1.2vw;
}

.admin-my-container-announcement {
    width: 87%;
    background-color: rgba(255, 255, 255, 0.3);
    /* Saydam arka plan */
    border-radius: 80px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 6%;
    box-shadow: inset 0px 2px 5px rgba(100, 100, 100, 0.9);
    /* Beyaz iç kenar gölgeliği */
}

.add-announcement-btn{
    cursor: pointer;
    background-color: #fff;
    color: #000;
    border-radius: 40px;
    width: 13vw;
    font-size: 1vw;
    font-family: "PoppinsLight";
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    transition: filter 0.3s ease-in-out;
    display: flex;
    justify-content: space-evenly;
}

.admin-dropdown {
    position: relative;
    border: 0.1rem solid black;
    border-radius: 4rem;
    background-color: #fff;
    padding: 0.5rem 0.8rem;
    cursor: pointer;
    width: 18%;
    align-self: flex-end;
    /* Bu, drapdown'ı sağa yaslayacak */
    margin-top: -3vw;
    margin-bottom: 2vw;

}

.admin-dropdown-content {
    position: absolute;
    top: 60px;
    /* Dropdown menünün açılacağı konum */
    right: 0;
    background-color: #f9f9f9;
    display: inline-block;
    min-width: 11vw;
    max-height: 13vw;
    overflow-y: auto;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    z-index: 1;

}

.admin-dropdown-content p {
    font-family: "PoppinsLight";
    font-size: 1.04vw;
    padding: 6px 18px;
    cursor: pointer;
    border-bottom: 0.5px solid #ddd;
}

.admin-dropdown-content p:hover {
    background-color: #f1f1f1;

}

.admin-dropdown-text {
    margin: 0;
    font-family: "PoppinsLight";
    font-size: 1.1vw;
    display: flex;
    justify-content: space-between;
    /* Yazı sola, ikon sağa hizalanır */

}

.admin-dropdown-icon {
    font-size: 1vw;
    padding: 0.2rem;

}

.admin-announcementPart {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

}



.card {
    border-radius: 20px;
    flex: 1 1 20vw;
    /* Kartlar birbirine sığacak şekilde genişler */
    max-width: calc(33.33% - 1rem);
    /* 3 kart yan yana olması için genişlik ayarlaması */
    height: 38vh;
    padding: 0;
    margin-bottom: 1.5rem;

    /* Card'lar arasında dikey boşluk */
}

.card-img-top {
    height: 25vh;
    /* Kart yüksekliğinin 3'te 1'i */
    border-top-left-radius: 20px;
    /* Sol üst köşe */
    border-top-right-radius: 20px;
    /* Sağ üst köşe */
    object-fit: cover;

}


.card-body {
    height: 13vh;
    /* Kart yüksekliğinin 3'te 2'si */

}


.card-title {
    font-family: "DMSansBold";
    font-size: 0.8vw;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Maksimum iki satır */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* Fazla metin için ... */
    margin-left: 10px;
}

.card-text {
    font-family: "DMSansRegular";
    font-size: 0.72vw;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Maksimum iki satır */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* Fazla metin için ... */
    margin: 10px;
}

.card-content {
    display: flex;
    text-align: start;

}

.card-date {
    font-family: "DMSansBold";
    font-size: 1.45vw;

}

.card-date-month {
    color: blue;
    font-family: "DMSansBold";
    font-size: 0.7vw;
    margin-left: 3px;
}

.card-date-day {
    margin-top: -1rem;
    font-size: 1.2vw;
    font-weight: bold;
}

.admin-pagination {
    display: flex;
    justify-content: center;
}

.admin-paginationButton {
    width: 1.5vw;
    height: 3vh;
    background: #1bb2ef;
    border-radius: 50%;
    margin-left: 0.3vw;
    position: relative;
    border: none;

}

.admin-paginationButton:hover,
.admin-paginationButton:active,
.admin-paginationButton:focus {
    background-color: #04399c;
    /* Üzerine gelindiğinde veya tıklandığında koyu mavi olur */
}

.admin-paginationButton.active {
    /* Aktif buton koyu mavi olur */
    background-color: #0659f1;
    color: white; /* Yazı rengi beyaz */
    font-family: "PoppinsLight";
}


  
.admin-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
}

.admin-close-button {
    position: absolute;
    top: 3vw;
    right: 4vw;
    background: #ff5f5f;
    border: none;
    color: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    z-index: 1000;
    
}

.admin-close-button :hover {
    background: #ff2f2f;
  }

.admin-modal-content {
    width: 70%;
    overflow-y: auto;
    position: relative;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2%;
    box-shadow: inset 0px 4px 5px rgba(100, 100, 100, 0.5);

}

.admin-modal-top-image {
    width: 40%;
    height: 25vh;
    position: relative;
    object-fit: fill;
    border-radius: 60px;
    margin-bottom: 1vh;
    border: #333 0.5px solid;
}

.custom-file-upload {
    cursor: pointer;
    background-color: #2b3cd8;
    color: #fff;
    border-radius: 40px;
    width: 14vw;
    font-size: 1vw;
    font-family: "PoppinsLight";
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    transition: filter 0.3s ease-in-out;
   
  }
  
  .custom-file-upload:hover {
    background-color: #0056b3;
  }
  
  .custom-file-upload input[type="file"] {
    display: none; /* Input'u görünmez yap */
  }

.admin-modal-body {
    width: 95%;
    max-height: 40vh;
    position: relative;
    padding: 1%;
    color: #333;
    display: flex;
    flex-direction: column;
    gap:1rem;

}

.admin-modal-title {
    font-size: 0.8vw;
    font-family: "DMSansBold";
    text-align: start;
    padding: 0.5rem;

}

.admin-modal-text {
    font-size: 0.9vw;
    font-family: "DMSansRegular";
    text-align: start;
    padding: 0.5rem;
}


.card-buttons{
    display: flex;
    flex-wrap: wrap;
    gap:1rem;
}

.admin-announcement-docs{
    cursor: pointer;
    background-color: #0056b3;
    color: #fff;
    border-radius: 40px;
    width: 12vw;
    font-size: 1vw;
    font-family: "PoppinsLight";
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    transition: filter 0.3s ease-in-out;
    /* Geçiş efekti */
    margin-top: 1rem;

}

.admin-announcement-delete {
    cursor: pointer;
    background-color: #b91919;
    color: #fff;
    border-radius: 40px;
    width: 12vw;
    font-size: 1vw;
    font-family: "PoppinsLight";
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    transition: filter 0.3s ease-in-out;
    /* Geçiş efekti */
    margin-top: 1rem;

}

.admin-announcement-save {
    cursor: pointer;
    background-color: #5c8617;
    color: #fff;
    border-radius: 40px;
    width: 12vw;
    font-size: 1vw;
    font-family: "PoppinsLight";
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    transition: filter 0.3s ease-in-out;
    /* Geçiş efekti */
    margin-top: 1rem;

}

@media (min-width: 768px) and (max-width: 1024px) {
    .admin-announcement-container {
        width: 100%;
        background-position: top;
    }

    .admin-my-container-announcement {
        width: 95%;  
        padding: 4%;  
        border-radius: 50px;

    } 

    .admin-first {
        width: 38vw;
    }

    .admin-first-text {
        font-size: 3vw;
    }

    .admin-announcementPart {
        flex-direction: column;
    }

    .card {
        flex: 1 1 100%;
        /* Card'lar tam genişlikte olur */
        max-width: none;
        /* Maksimum genişlik sınırlaması kaldırılır */

    }

    .add-announcement-btn{
        width: 35vw;
        font-size: 2.5vw;
        display: flex;
        justify-content: space-evenly;
        align-self: center;
        margin-bottom: 3rem;
    }
    
    .admin-dropdown {
        align-self: center;
        width: 40%;

    }

    .admin-dropdown-content {
        top: 55px;
        width: 100%;
        max-height: 25vw;
    }

    .admin-dropdown-content p {
        font-size: 2.2vw;
        padding: 6px 15px;

    }

    .admin-dropdown-text {
        font-size: 2.5vw;
    }

    .admin-dropdown-icon {
        font-size: 2.5vw;
    }  
  
    .admin-paginationButton {
        width:2.5vw;
        height: 1.7vh;
    
    }

    .admin-modal {
  
        width: 100vw;
        height: 100vh;
    }

    .admin-modal-content {
        border-radius: 20px;
        width: 97%;

    }

    .admin-modal-top-image {
        width: 70%;
        height: 25vh;
        position: relative;
        object-fit: fill; 
        border-radius: 20px;
        margin-bottom: 1vh;
    }  

    .admin-modal-body {
        width: 97%;
        border-radius: 20px;
        max-height: 45vh;
    }

    .admin-close-button {
        top: 4vw;
        right: 4vw;
        background: #ff5f5f;
        border: none;
        color: white;
        border-radius: 50%;
        width: 50px;
        height: 50px;
    }

    .admin-modal-title {
        font-size: 2.1vw;

    }

    .admin-modal-text {
        font-size: 2vw;
    }

    .admin-announcement-delete ,.admin-announcement-save, .custom-file-upload,.admin-announcement-docs{
        width: 26vw;
        font-size: 2.3vw;
    }

    .card-buttons{
        flex-direction: column;
        gap:0.7rem;

    }


}

/* Ekran genişliği 768px veya daha küçük olduğunda */
@media (max-width: 768px) {

    .admin-announcement-container {
        width: 100%;
        background-position: top;
    }

    .admin-my-container-announcement {
        width: 95%;  
        padding: 4%;  
        border-radius: 50px;

    } 

    .admin-first {
        width: 40vw;
    }

    .admin-first-text {
        font-size: 3vw;
    }

    .admin-announcementPart {
        flex-direction: column;
    }

    .card {
        flex: 1 1 100%;
        /* Card'lar tam genişlikte olur */
        max-width: none;
        /* Maksimum genişlik sınırlaması kaldırılır */

    }

    .add-announcement-btn{
        width: 35vw;
        font-size: 3vw;
        display: flex;
        justify-content: space-evenly;
        align-self: center;
        margin-bottom: 1.6rem;
    }
    .admin-dropdown {
        align-self: center;
        width: 40%;

    }

    .admin-dropdown-content {
        top: 40px;
        width: 98%;
        max-height: 25vw;
    }

    .admin-dropdown-content p {
        font-size: 2.5vw;
        padding: 6px 15px;

    }

    .admin-dropdown-text {
        font-size: 3vw;
    }

    .admin-dropdown-icon {
        font-size: 3vw;
    }

    .card-title {
        font-size: 3.5vw;
    }

    .card-text {
        font-size: 3vw;
    }

    .card-date-month {
        font-size: 3vw;
    }

    .card-date-day {
        font-size: 4vw;
    }

    .admin-paginationButton {
        width:4vw;
        height: 3vh;
    
    }

    .admin-modal-content {
        border-radius: 30px;
        width: 95%;

    }

    .admin-modal-top-image {
        width: 60%;
        height: 18vh;
        position: relative;
        object-fit: fill; 
        border-radius: 30px;
        margin-bottom: 1vh;
    }
  

    .admin-modal-body {
        width: 85%;
        border-radius: 20px;
        max-height: 40vh;
    }

    .admin-close-button {
        top: 4vw;
        right: 4vw;
        background: #ff5f5f;
        border: none;
        color: white;
        border-radius: 50%;
        width: 30px;
        height: 30px;
    }

    .admin-modal-title {
        font-size: 3.2vw;

    }

    .admin-modal-text {
        font-size: 3.2vw;
    }

    .admin-announcement-delete ,.admin-announcement-save, .custom-file-upload,.admin-announcement-docs{
        width: 30vw;
        font-size: 2.2vw;
    }

    .card-buttons{
        flex-direction: column;
        gap:0rem;

    }


}